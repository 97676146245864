import React, { useState, useContext, useEffect } from "react";
import {
    AppContainer,
    AppLeftPane,
    AppContent,
    AppMainContent,
    Collapsible,
    CollapsibleContainer,
    Icon,
    SidebarNavi,
    SidebarNaviTile,
    Checkbox
} from "@abb/abb-common-ux-react";
import { RootContext } from "../../contexts/root-context";
import { BASE_URL } from "../../strings";
import axios from "axios";
import { getToken } from "../Authentication/get-token";
import "./left-pane.css";
import config from './left-pane.json';
//import { validateJsonSchema, isJsonValidSchema } from "../../util";

const LOCAL_STORAGE_KEYS = {
    SAOA: "SAOA",
    ORList: "ORList",
    SubTypeList: "SubTypeList",
    SAST: "SAST",
    selectedSubTypes: "selectedSubTypes",
    opsArea: "opsarea",
    primaryFilter: "primaryFilter",
    selectedOpsAreas: "selectedOpsAreas"
};


const LeftpaneComponent = () => {
    const { isLeftPaneOpen, setisLeftPaneOpen, setprimaryFilter, setOpsAreaFilter, startTS, endTS } = useContext(RootContext);
    const [sidebarNaviStates, setSidebarNaviStates] = useState({
        search: false,
        filters: true
    });
    const [collapsed, setCollapsed] = useState(false);
    const [theme, setTheme] = useState("light");
    const [searchBar, setSearchBar] = useState(false);
    const [border, setBorder] = useState(false);
    const [isTitleFor, setisTitleFor] = useState("filters");
    const [isInline, setIsInline] = useState(false);
    const [leftPaneFilter, setLeftPaneFilter] = useState("");
    const [currentTile, setCurrentTile] = useState("filters");
    const [subTypeCheckedItems, setSubTypeCheckedItems] = useState({})
    const [opsAreacheckedItems, setOpsAreaCheckedItems] = useState({})
    const [sdata, setsdata] = React.useState([]);
    const [ORList, setORList] = useState([]);
    const [isError, setError] = useState([]);
    const [subTypeList, setSubTypeList] = useState([]);
    const [selectAllSubstationType, setSelectAllSubstationType] = useState(true);
    const [selectAllOperationAreas, setSelectAllOperationAreas] = useState(true);

    // useEffect(() => {
    //     const jsonString = JSON.stringify(config);
     
    //     if (isJsonValidSchema(jsonString)) {
    //       console.log("JSON being validated:", config);
    //       validateJsonSchema(config);
    //     } else {
    //       setError("Invalid JSON syntax: Missing braces or commas.");
    //     }
    //   }, []);
     

    useEffect(() => {
        const opsArea = localStorage.getItem(LOCAL_STORAGE_KEYS.opsArea);
        const primaryFilter = localStorage.getItem(LOCAL_STORAGE_KEYS.primaryFilter);
        const SAST = localStorage.getItem(LOCAL_STORAGE_KEYS.SAST);
        const SAOA = localStorage.getItem(LOCAL_STORAGE_KEYS.SAOA);
        const ORListlocal = sessionStorage.getItem(LOCAL_STORAGE_KEYS.ORList) !== null ? JSON.parse(sessionStorage.getItem(LOCAL_STORAGE_KEYS.ORList)) : ORList;
        const SubTypeListlocal = localStorage.getItem(LOCAL_STORAGE_KEYS.SubTypeList) !== null ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.SubTypeList)) : subTypeList;
        if (opsArea == "NA" || primaryFilter == "NA" || ORListlocal?.length === 0 || SubTypeListlocal?.length === 0 || SAST == "NA" || SAOA == "NA") {
            fetchSubstationData();
        } else {
            setSubTypeCheckedItems(JSON.parse(primaryFilter));
            setOpsAreaCheckedItems(JSON.parse(opsArea));
            setSelectAllSubstationType(JSON.parse(SAST));
            setSelectAllOperationAreas(JSON.parse(SAOA));
            setORList(JSON.parse(ORListlocal));
            setSubTypeList(JSON.parse(SubTypeListlocal));
        }
    }, []);
    const na = "NA"
    useEffect(() => {
        localStorage.setItem(LOCAL_STORAGE_KEYS.primaryFilter, subTypeCheckedItems ? JSON.stringify(subTypeCheckedItems) : na);
        localStorage.setItem(LOCAL_STORAGE_KEYS.opsArea, opsAreacheckedItems ? JSON.stringify(opsAreacheckedItems) : na);
        localStorage.setItem(LOCAL_STORAGE_KEYS.SAST, selectAllSubstationType);
        localStorage.setItem(LOCAL_STORAGE_KEYS.SAOA, selectAllOperationAreas);
    }, [subTypeCheckedItems, opsAreacheckedItems, selectAllSubstationType, selectAllOperationAreas, ORList, subTypeList]);

    const activateSidebarTile = (tile) => {
        setCurrentTile(tile);
        if (tile === "search") {
            setisTitleFor(config.titleSearch);
        } else {
            setisTitleFor(config.titleFilters);
        }
        const updatedSidebarNaviStates = {};
        Object.keys(sidebarNaviStates).map((v) => (updatedSidebarNaviStates[v] = v === tile));
        if (updatedSidebarNaviStates) {
            setSidebarNaviStates(updatedSidebarNaviStates);
        }
    };

    const fetchSubstationData = async () => {

        let opArs = await JSON.parse(localStorage.getItem("USER_INFO"))?.operationAreas;
        let baseURL =
            BASE_URL + `substation-details?startDateTime=${startTS}&endDateTime=${endTS}&operationAreas=${opArs}`;
        try {
            const response = await axios.get(baseURL, getToken());
            let substationData = response?.data;
            let formatedSubData = substationData?.map((v, i) => ({
                fields: {
                    operationArea: v?.operationArea,
                    substationName: v?.name,
                    substationCode: v?.substationId,
                    substationType: v?.type,
                    coordinates: v?.coordinates,
                },
            }));
            let userInfo = JSON.parse(localStorage.getItem("USER_INFO"));

            let filteredData = formatedSubData?.filter(data => {
                const lowercasedUserInfo = userInfo?.operationAreas?.map(area => area.toLowerCase());
                const lowercasedDataArea = data?.fields?.operationArea.toLowerCase();
                return lowercasedUserInfo.includes(lowercasedDataArea);
            });
            setsdata(filteredData);
            let subTypes = filteredData?.map(item => item.fields.substationType);
            let uniqueSubTypes = [...new Set(subTypes)];
            setSubTypeList(uniqueSubTypes);
            localStorage.setItem("SubTypeList", JSON.stringify(uniqueSubTypes));
            setSubTypeCheckedItems(uniqueSubTypes?.reduce((acc, item) => {
                acc[item] = true;
                return acc;
            }, {}));
            let operationAreas = filteredData?.map(item => item.fields.operationArea);
            let uniqueoperationAreas = [...new Set(operationAreas)];
            setORList(uniqueoperationAreas);
            localStorage.setItem("ORList", JSON.stringify(uniqueoperationAreas));
            setOpsAreaCheckedItems(uniqueoperationAreas?.reduce((acc, area) => {
                acc[area] = true;
                return acc;
            }, {}));
        } catch (error) {
            console.error("An error occurred while processing the areas:", error);
        }
    };

    const handleSubTypeCheckboxChange = (item) => {
        setSubTypeCheckedItems(prevState => ({
            ...prevState,
            [item]: !prevState[item]
        }));
        const selectedTypes = Object.entries(subTypeCheckedItems)
            .filter(([key, value]) => value === true)
            .map(([key]) => key);
        setprimaryFilter(selectedTypes)

        setSelectAllSubstationType(false);
        localStorage.setItem(LOCAL_STORAGE_KEYS.SAST, JSON.stringify(selectAllSubstationType));
        localStorage.setItem(LOCAL_STORAGE_KEYS.selectedSubTypes, JSON.stringify(selectedTypes));

    };

    const handleOpsAreaCheckboxChange = (area) => {
        setOpsAreaCheckedItems(prevState => ({
            ...prevState,
            [area]: !prevState[area]
        }));
        const selectedAreas = Object.entries(opsAreacheckedItems)
            .filter(([key, value]) => value === true)
            .map(([key]) => key);
        setOpsAreaFilter(selectedAreas)
        setSelectAllOperationAreas(false);
        localStorage.setItem(LOCAL_STORAGE_KEYS.SAOA, JSON.stringify(selectAllOperationAreas));
        localStorage.setItem(LOCAL_STORAGE_KEYS.selectedOpsAreas, JSON.stringify(selectedAreas));

    };

    const handleSelectAllSubstationType = () => {
        setSelectAllSubstationType(!selectAllSubstationType);
        const updatedCheckedItems = subTypeList?.reduce((acc, item) => {
            acc[item] = !selectAllSubstationType;
            return acc;
        }, {});
        setSubTypeCheckedItems(updatedCheckedItems);
        setprimaryFilter(selectAllSubstationType ? [] : subTypeList);
    };

    const handleSelectAllOperationAreas = () => {
        setSelectAllOperationAreas(!selectAllOperationAreas);
        const updatedOpsAreaCheckedItems = ORList?.reduce((acc, area) => {
            acc[area] = !selectAllOperationAreas;
            return acc;
        }, {});
        setOpsAreaCheckedItems(updatedOpsAreaCheckedItems);
        setOpsAreaFilter(selectAllOperationAreas ? [] : ORList);
    };

    return (
        <div className="left-pane-container">
            <AppContainer theme={theme} className="filter-container-main" style={{ height: "calc(100% - 16px)", position: "absolute", zIndex: 3, minWidth: "30px" }}>
                <AppContent style={{ width: "100%", background: "none" }}>
                    <AppLeftPane
                        searchValue={leftPaneFilter}
                        className="filter-app-left-pane"
                        icon={config.icon}
                        showSearchBarInFullMode={searchBar}
                        title={isTitleFor === "search" ? <span className="pane-header">{config.titleSearch}</span> : <span className="pane-header">{config.titleFilters}</span>}
                        searchBarPositionInFullMode={isInline ? "below" : "inline"}
                        searchBarPlaceholder={config.searchBarPlaceholder}
                        showRightBorder={border}
                        onSearchValueChange={(value) => setLeftPaneFilter(value)}
                        visibility={isLeftPaneOpen}
                        onClickToggleVisibility={() => {
                            setCollapsed(!collapsed);
                            setisLeftPaneOpen(collapsed ? "full" : "thumbs");
                        }}
                    >
                        {isLeftPaneOpen === "full" && (
                            <CollapsibleContainer filter={leftPaneFilter}>
                                <SidebarNavi style={config.sidebarNaviStyle} className="filter-sidebar-navi">
                                    <SidebarNaviTile
                                        onClick={(e) => activateSidebarTile("filters")}
                                        style={{ background: "none" }}
                                        active={sidebarNaviStates?.filters}
                                        mode={"thumbs"}
                                        title="Filters"
                                    >
                                        <Icon name={config.iconSettings.name} sizeClass={config.iconSettings.sizeClass} />
                                    </SidebarNaviTile>
                                </SidebarNavi>
                                <AppMainContent style={config.appMainContentStyle}>
                                    {currentTile === "filters" && (
                                        <div style={config.collapsibleContainerStyle}>
                                            <CollapsibleContainer>
                                                {config.showSubstationType && subTypeList && subTypeList.length > 0 ? ( // <-- Added condition to check subTypeList
                                                    <Collapsible itemId="sub-type" className="filters-header" title={config.substationTypeTitle}>
                                                        <Checkbox
                                                            sizeClass={config.checkboxSizeClass}
                                                            label="Select All"
                                                            monochrome={true}
                                                            value={selectAllSubstationType}
                                                            onChange={() => handleSelectAllSubstationType()}
                                                        />
                                                        {subTypeList.map((item, index) => (
                                                            <Checkbox
                                                                key={index}
                                                                sizeClass={config.checkboxSizeClass}
                                                                monochrome={true}
                                                                label={item}
                                                                value={subTypeCheckedItems[item]}
                                                                tile={item}
                                                                className={config.checkboxClassName}
                                                                onChange={() => handleSubTypeCheckboxChange(item)}
                                                            />
                                                        ))}
                                                    </Collapsible>
                                                ) : (
                                                    <div></div> // <-- Added fallback content
                                                )}
                                                {config.showOperationAreas && ORList && ORList.length > 0 ? ( // <-- Added condition to check ORList
                                                    <Collapsible itemId="sub-sites" className="filters-header" title={config.operationAreasTitle}>
                                                        <Checkbox
                                                            sizeClass={config.checkboxSizeClass}
                                                            label="Select All"
                                                            monochrome={true}
                                                            value={selectAllOperationAreas}
                                                            onChange={() => handleSelectAllOperationAreas()}
                                                        />
                                                        {ORList.map((area) => (
                                                            <Checkbox
                                                                key={area}
                                                                sizeClass={config.checkboxSizeClass}
                                                                label={area}
                                                                monochrome={true}
                                                                value={opsAreacheckedItems[area]}
                                                                onChange={() => handleOpsAreaCheckboxChange(area)}
                                                            />
                                                        ))}
                                                    </Collapsible>
                                                ) : (
                                                    <div></div> // <-- Added fallback content
                                                )}
                                            </CollapsibleContainer>
                                        </div>
                                    )}
                                </AppMainContent>
                            </CollapsibleContainer>
                        )}
                        {isLeftPaneOpen === "thumbs" && (
                            <CollapsibleContainer filter={leftPaneFilter}>
                                <SidebarNavi style={config.sidebarNaviStyle} className="filter-sidebar-navi">
                                    <SidebarNaviTile
                                        onClick={(e) => activateSidebarTile("filters")}
                                        style={{ background: "none" }}
                                        active={sidebarNaviStates?.filters}
                                        mode={"thumbs"}
                                        title="Filters"
                                    >
                                        <Icon name={config.iconSettings.name} sizeClass={config.iconSettings.sizeClass} />
                                    </SidebarNaviTile>
                                </SidebarNavi>
                            </CollapsibleContainer>
                        )}
                    </AppLeftPane>
                </AppContent>
            </AppContainer>
        </div>
    );
};

export default LeftpaneComponent;