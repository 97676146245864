// export const BASE_URL = `https://${process.env.REACT_APP_BASE_URL}.execute-api.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${process.env.REACT_APP_LAMBDA_ENV}/`;

// The SUBSTATION_ID constant was previously used to identify a specific substation but is now commented out as it is no longer needed.
//export const SUBSTATION_ID = "BAY-DS-8451"

export const BASE_URL_CAES = `https://api.${process.env.REACT_APP_LAMBDA_ENV}.${process.env.REACT_APP_PROJECT_NAME}.cognien.abb/caesml/`;
export const S3_BUCKET_DR_CAES = `s3://${process.env.REACT_APP_CAES_DR_S3_BUCKET_NAME}/`;

export const BASE_URL_MFA = `https://${process.env.REACT_APP_AWS_COGNITO_DOMAIN}.auth.${process.env.REACT_APP_AWS_REGION}.amazoncognito.com/mfa?client_id=${process.env.REACT_APP_AWS_COGNITO_CLIENT_ID}&response_type=code&scope=email+openid+profile&redirect_uri=https%3A%2F%2F${process.env.REACT_APP_LAMBDA_ENV}.${process.env.REACT_APP_PROJECT_NAME}.cognien.abb%2F`
export const BASE_URL = `https://api.${process.env.REACT_APP_LAMBDA_ENV}.${process.env.REACT_APP_PROJECT_NAME}.cognien.abb/dashboard/`;
export const BASE_URL_LOG = `https://api.${process.env.REACT_APP_LAMBDA_ENV}.${process.env.REACT_APP_PROJECT_NAME}.cognien.abb/request/`;
export const OPERATION_AREA = "Bayrampaşa";
export const DEFAULT_SUBSTATION = "BAY-DS-8451";
export const DIALOG_MESSAGES = {
  ACK_DR_FILE: "Are you sure you want to acknowledge : ",
};
export const MAP_BOUNDRIES = {
  north: 42.1864,
  south: 39.8027,
  west: 27.5836,
  east: 29.4099,
}
export const mapCenter = {
  lat: 41.0082,
  lng: 28.9784
};

export const ERROR_LIST = {
  ERROR_FETCH_DATA: "There was a problem fetching the data",
  ALARM_SUMMARY_EMPTY_DATA: "There are no alarms to display at this time.",
  NO_EVENTS: "There are no events available at this time.",
  NO_RECORDS: "There are no records available at this time.",
  NO_DATA: "There is no data available at this time.",
  NO_NOTIFICATIONS: "There are no new notifications at this time.",
  NO_CRITICAL_ALERTS: "There are no critical alerts to display at this time.",
  WAIT: "Please wait while data loading...",
  MAP_NOT_LOADED: "Map cannot be loaded right now, sorry."
};

export const MESSAGES = {
  SIGN_IN_TRIGGER: "Verifying Credentials, Please Wait...",
  SIGN_IN_TIME_OUT_TRIGGER: "",
  POST_SIGN_WAITING: "Waiting for User Mail Verification",
  // POST_SIGN_UP_TRIGGER: "Thank you for creating an account with CogniEN.\n An email has been sent to your registered email. Please verify it to obtain approval from the administrators",
  // POST_SIGN_UP_TRIGGER: `Thank you for creating an account with CogniEN.
  // To ensure your email is valid, you will receive two verification emails:
  // 1️⃣ **AWS SES Email Verification** – Please check your inbox for an email from AWS SES and follow the instructions to verify your email identity. 
  // 2️⃣ **CogniEN Account Confirmation** – You will also receive an email from CogniEN with a verification link to complete your account setup.  
  // **Please complete both verifications to obtain approval from the administrators.**`, 
  MFA_QR_CODE_PREFIX: "otpauth://totp/bedas-dev.auth.eu-central-1.amazoncognito.com:",
  MFA_EMAIL_ABB_LOGO_URL: "https://qr-code-storage-test.s3.eu-central-1.amazonaws.com/Logo.png",
  MFA_QR_CODE_SECRET_PREFIX: "?secret=",
  MFA_EMAIL_SENDER: "cognien.abb.noreply@gmail.com",
  MFA_EMAIL_SUBJECT: "Secure your CogniEn account with Two-Factor Authentication",
  MFA_EMAIL_TITLE: "Follow these steps to set up two-factor authentication:",
  MFA_INSTRUCTIONS_STEP_1: "1. Install an authenticator app on your mobile device",
  MFA_INSTRUCTIONS_STEP_2: "2. Scan this QR code with your authenticator app. ",
  MFA_INSTRUCTIONS_ALTERNATIVE_STEP: "Alternatively, you can manually enter a secret key in your authenticator app. ",
  MFA_INSTRUCTIONS_ALTERNATIVE_STEP_FOR_EMAIL: "If you want to manually enter a secret key in your authenticator app, the secret key is: ",
  FORGOT_PASSWORD_TITLE: "Password Change Successful",
  FORGOT_PASSWORD_BODY: "Your password has been changed successfully. Please login with your new password.",
  CONFIRM_USER_TITLE: "Confirm User Details",
  CONFIRM_USER_BODY: "Are you sure you want to save user details?",
  CONFIRMED_USER_TITLE: "Updated User Details",
  CONFIRMED_USER_BODY: "User Details updated successfully. The updates will be reflected in the next login",
  SUBSTATION_AREAS_LOAD_MESSAGE: "Please wait while we fetch the substations from the assigned operation areas",
  SUBSTATION_LOADING: "Loading substations..."

}
export const SUBSTATION_ROLES = [
  {
    "label": "Operational Manager",
    "value": "OperationalManager",
  },
  {
    "label": "Maintenance Engineer",
    "value": "MaintenanceEngineer",
  },
  {
    "label": "Technician",
    "value": "Technician",
  },

];

export const CHATBOT_REDIRECT_LINK = "https://mira-access.miraclesoft.com/"

export const ROUTE_PAGES = {
  SUBSTATION_EXPLORER: 'substation-explorer',
  SETTINGS: 'settings',
  HOME: 'Home'
};

