import React, { useContext } from "react";
import { NotificationContainer } from "@abb/abb-common-ux-react";
import { RootContext } from "../../contexts/root-context";
import "./notification.css";
import jsonSchema from "./notification-scheme.json";

export default function NotificationExample({ notitype }) {
    const { drwidgetConfig } = useContext(RootContext);

    // Define notification data as an object
    const notificationsMap = {
        "true": [{
            id: 1,
            type: "banner",
            discreet: 1,
            severity: "info",
            timeout: 3000,
            text: "Please wait while we open the file in wavewin",
        }],
        "error": [{
            id: 2,
            type: "banner",
            discreet: 2,
            severity: "warn",
            timeout: 3000,
            text: "Error while generating URL.",
        }],
        "updatePassword": [{
            id: 1,
            type: "banner",
            discreet: 1,
            severity: "success",
            timeout: 5000,
            text: "Your password has been changed successfully",
        }],
        "dr-acked": [{
            id: 1,
            type: "banner",
            discreet: 1,
            severity: "success",
            timeout: 3000,
            text: `DR File ${drwidgetConfig[0]?.currentFile} Successfully Acknowledged`,
        }],
        "genrateReport": jsonSchema.generateReport?.enabled ? [{
            id: jsonSchema.properties.notifications.properties.generateReport.properties.id,
            type: jsonSchema.properties.notifications.properties.generateReport.properties.type,
            discreet: jsonSchema.properties.notifications.properties.generateReport.properties.discreet,
            severity: jsonSchema.properties.notifications.properties.generateReport.properties.severity,
            timeout: jsonSchema.properties.notifications.properties.generateReport.properties.timeout,
            text: jsonSchema.properties.notifications.properties.generateReport.properties.text,
        }] : null,
        "nodr": jsonSchema.nodr?.enabled ? [{
            id: jsonSchema.properties.notifications.properties.nodr.properties.id,
            type: jsonSchema.properties.notifications.properties.nodr.properties.type,
            discreet: jsonSchema.properties.notifications.properties.nodr.properties.discreet,
            severity: jsonSchema.properties.notifications.properties.nodr.properties.severity,
            timeout: jsonSchema.properties.notifications.properties.nodr.properties.timeout,
            text: jsonSchema.properties.notifications.properties.nodr.properties.text,
        }] : null,
        "hr": [{
            id: 1,
            type: "banner",
            discreet: 1,
            severity: "success",
            timeout: 3000,
            text: "Report sent to your mail",
        }],
        "sub-success": [{
            id: 1,
            type: "banner",
            discreet: 1,
            severity: "success",
            timeout: 3000,
            text: "Substations details updated successfully.",
        }],
        "sub-failure": [{
            id: 1,
            type: "banner",
            discreet: 1,
            severity: "alarm",
            timeout: 3000,
            text: "Failed to upload file content.",
        }],
        "sub-delete": [{
            id: 1,
            type: jsonSchema.properties.subdeletenotifications.items.properties.type.default,
            discreet: 1,
            severity: jsonSchema.properties.subdeletenotifications.items.properties.severity.default,
            timeout: jsonSchema.properties.subdeletenotifications.items.properties.timeout.default,
            text: jsonSchema.properties.subdeletenotifications.items.properties.text.default,
        }],
        "remove-user": [{
            id: 1,
            type: jsonSchema.properties.userremovenotifications.items.properties.type.default,
            discreet: 1,
            severity: jsonSchema.properties.userremovenotifications.items.properties.severity.default,
            timeout: jsonSchema.properties.userremovenotifications.items.properties.timeout.default,
            text: jsonSchema.properties.userremovenotifications.items.properties.text.default,
        }],
        "sld-success": [{
            id: 1,
            type: "banner",
            discreet: 1,
            severity: "success",
            timeout: 3000,
            text: "File successfully uploaded.",
        }],
        "sld-failure": [{
            id: 1,
            type: "banner",
            discreet: 1,
            severity: "alarm",
            timeout: 3000,
            text: "Failed to upload.",
        }],
        "sld-too-large": [{
            id: 1,
            type: "banner",
            discreet: 1,
            severity: "alarm",
            timeout: 3000,
            text: "File size exceeds 5MB. Please choose a smaller file.",
        }],
        "sld-invalid-format": [{
            id: 1,
            type: "banner",
            discreet: 1,
            severity: "alarm",
            timeout: 3000,
            text: "Invalid file format. Please choose a PDF file.",
        }],
        "no-sub-assign": [{
            id: 1,
            type: "banner",
            discreet: 1,
            severity: "info",
            text: "No operation area assigned, Please contact Ops. Manager.",
        }],
        "invalid-substation": [{
            id: 1,
            type: "banner",
            discreet: 1,
            severity: "alarm",
            text: "You are not assigned to this substation.",
        }],
        "loading": [{
            id: 1,
            type: "banner",
            discreet: 1,
            severity: "info",
            text: "Please wait while we fetch the substations from the assigned operation areas",
        }],
        "postLogout": [{
            id: 1,
            type: "banner",
            discreet: 1,
            severity: "info",
            timeout: 5000,
            text: "User has been logged out, please wait while redirecting to login.",
        }]
    };

    // Get the notifications based on notitype
    const notifications = notificationsMap[notitype];

    return (
        <>
            {notifications && (
                <NotificationContainer
                    notifications={notifications}
                    style={{
                        position: "absolute",
                        top: "100px",
                        right: "0px",
                        zIndex: 9,
                        textAlign: "left",
                    }}
                />
            )}
        </>
    );
}
