import React, { useContext, useState,useMemo,useCallback,useEffect } from "react";
import { TabItem, TabControl ,WithPopup,Popup,Dialog} from "@abb/abb-common-ux-react";
import Card from "../../components/Card/card";
import SubstationFilters from "../substation-explorer/substation-filters";
import DashboardMetrics from "./metrics";
import DisturbanceEvents from "../../components/Bar-Disturbance-Events/disturbance-events";
import AlarmSummary from "../../components/Donut-Alarm-Summary/alarm-summary";
import DisturbanceMeasurementData from "../../components/Disturbance-Records/disurbance-measurements-datagrid";
import AlarmNotificationsData from "../../components/Alarm-Notifications/alarm-noti-datagrid";
import Sld from "../../components/SLD/sld";
import HistoricalReports from "../../components/Historical-Reports/historical-reports";
import { RootContext } from "../../contexts/root-context";
import { CHATBOT_REDIRECT_LINK } from "../../strings";
import chatbotConfigData from './chatbotConfig.json'
import chatbotIcon from '../../assets/chat-bot.png';
import { validateJson, isJsonValid } from '../../util';
import metricsData from './dashboard-schema.json';
function Dashboard() {
  const { relay, drmetrics } = useContext(RootContext);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { chatbotEnabled,iframeEnabled, className, title, image } =chatbotConfigData;
 

  const [activeTab, setActiveTab] = React.useState(0);


  const validateChatbotConfigData=useCallback(()=>{
        const jsonString = JSON.stringify(chatbotConfigData); 
         
        if (isJsonValid(jsonString)) {
          validateJson( chatbotConfigData,chatbotConfigData.chatboatSchema); 
        } else {
          
          console.error('Invalid JSON syntax: Missing braces or commas.');
        }
      }, [chatbotConfigData]);
    
    
    useEffect(() => {
      validateChatbotConfigData();
    
    }, [validateChatbotConfigData]);


  // Helper function to update metrics
  const generateMetrics = (id, data) => {
    console.log(data);
    
    return metricsData[id]
      .filter((metric) => metric.enabled)  
      .map((metric) => ({
        ...metric,
        metricStat:data.metricStat || 0,
        metricScore: data.metricScore || 0
      }));
  };

  // Memoized metrics to avoid unnecessary recalculations
  const metrics = useMemo(() => ({
    tripMetric: generateMetrics("tripMetric", {metricStat:relay?.tripsChange, metricScore: relay?.trips }),
    distRecordsMetric: generateMetrics("distRecordsMetric", {metricStat:drmetrics?.drchange, metricScore: drmetrics?.drs }),
    ackDRMetric: generateMetrics("ackDRMetric", {metricStat:drmetrics?.ackDRChange, metricScore: drmetrics?.ackDR }),
    unackDRMetric: generateMetrics("unackDRMetric", {metricStat:drmetrics?.unAckDRChange, metricScore: drmetrics?.unAckDR }),
    healthyRelayMetrics: generateMetrics("healthyRelayMetrics", {metricStat:relay?.healthyRelaysChange, metricScore: relay?.healthyRelays }),
    unHealthyRelayMetrics: generateMetrics("unHealthyRelayMetrics", {metricStat:relay?.unhealthyRelaysChange, metricScore: relay?.unhealthyRelays })
  }), [relay, drmetrics]);

  /*Chatbot*/
  const clearData = () => {
    setIsDialogOpen(false);
  }
  
  return (
    <>
      {isDialogOpen &&
        (<Dialog
          className="dialog-container-chatbot"
          showCloseButton={true}
          closeOnEscape={true}
          closeOnLostFocus={true}
          hideBackground={true}

          onClose={() => {
            clearData();
          }}
        >
         
          
        
          {chatbotEnabled && (
            <div className={className}>
              <img className={image?.className} src={chatbotIcon} alt={image?.alt} />
              <span className={title?.className}>{title?.text}</span>
            </div>
          )}

  

        {iframeEnabled && (
          <iframe
          
            className="chat-window"
            src={CHATBOT_REDIRECT_LINK} 
            title="Chatbot"
          />
        )}

        </Dialog >)
      }
      <div className="row">
        <div className="dashboard-page-content" >
       
          <TabControl
            containerId="tab-container"
            activeTab={activeTab}
            onTabChange={(oldIndex, newIndex) => setActiveTab(newIndex)}
          >

            <TabItem title="DR Monitoring Dashboard">
              <div className="row">
                <SubstationFilters
                  substationTitle={"Substation DR Monitoring Dashboard"}

                />
              </div>
              <div className="row">

                {Object.entries(metrics).map(([key, metricData]) => {
                  // Filter to include only metrics where enabled is true
                  const enabledMetrics = metricData.filter(metric => metric.enabled);

                  // Render DashboardMetrics only if there are enabled metrics
                  return (
                    enabledMetrics.length > 0 && (
                      <div key={key} className="col-sm-6 col-md-4 col-lg-4 col-xl-2" style={{ paddingLeft: "16px" }}>
                        <DashboardMetrics position={3} legends={enabledMetrics} />
                      </div>
                    )
                  );
                })}
              </div>
              <div className="row"  >
                <div className="col-5 widgets-container">
                  <Sld />
                </div>
                <div className="col-4 widgets-container">
                  <Card
                    widgetName="Disturbance Records"
                    widgetUpdateTime="2"
                    component={<DisturbanceMeasurementData />}
                  ></Card>
                  <Card
                    widgetName="Alarm Notifications"
                    widgetUpdateTime="2"
                    component={<AlarmNotificationsData />}
                  ></Card>
                </div>
                <div className="col-3 widgets-container">
                  <Card
                    widgetName="Disturbance Occurrence"
                    widgetUpdateTime="2"
                    component={<DisturbanceEvents />}
                  >
                  </Card>
                  <Card
                    widgetName="Alarm Summary"
                    widgetUpdateTime="2"
                    component={<AlarmSummary />}
                  ></Card>
                </div>
              </div>
            </TabItem>
            <TabItem title="Historical Reports">
              <HistoricalReports />
            </TabItem>

          </TabControl>


          <div>
            <div id="tab-container" />
          </div>


        </div>

         <div id="chatbot-icon">
          <WithPopup >
            <button onClick={() => { setIsDialogOpen(true) }} id="chatbot-icon-link" target="_blank">
              <img src={chatbotIcon} alt="Chatbot Icon" />
            </button>
            <Popup
              trigger={'hover'}
              closeOnLostFocus={true}
              position={'top right'}
              className="c-bot-tooltip" followCursor={false} triggerOnClick={false}>
              <div className="c-bot-tooltip" >
                Hello! I am CogniE,<br />
                How can I Cognify you today?
              </div>
            </Popup>
          </WithPopup>

        </div> 

        


      </div>
    </>
  );
}
export default Dashboard;
