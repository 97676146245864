import React, { useEffect, useState } from "react";

import jsonSchema from './edit-substation-schema.json';
import uiSchema from './edit-substation-uischema.json';

import { Input, Button, Dialog, Icon } from "@abb/abb-common-ux-react";
import "./edit-user.css";
import { BASE_URL } from "../../strings";
import axios from "axios";
import "./manage-users.css";
import { getToken } from "../../components/Authentication/get-token";
import {  validateJsonSchema, isJsonValidSchema } from "../../util";
import PropTypes from "prop-types";



const EditSubstation = ({ editfunc }) => {
  const [substationDetails, setSubstationDetails] = useState({});
  const [editedDetails, setEditedDetails] = useState({});
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  
  useEffect(() => {
    const jsonString = JSON.stringify(jsonSchema);
    if (isJsonValidSchema(jsonString)) {
      console.log('JSON being validated: notification', jsonSchema);
      validateJsonSchema(jsonSchema); // Pass ignored properties
    } else {
      console.error('Invalid JSON syntax: Missing braces or commas.');
    }
  }, []);



  const handleClick = () => {
    if (uiSchema.onClick["ui:widget"] === "function") {
      eval(jsonSchema.properties.onClick); // Execute the onClick action from dataSchema
    }
  };
  useEffect(() => {
    setSubstationDetails(JSON.parse(localStorage.getItem('substation')) || {});
  }, []);

  useEffect(() => {

  }, [isEditDialogOpen])

  const handleInputChange = (fieldName, value) => {
    setEditedDetails((prevDetails) => ({
      ...prevDetails,
      [fieldName]: value,
    }));
  };

  const handleEdit = async () => {
    const url = BASE_URL + `substation-details-edit`;
    const updatedSubstationDetails = {
      ...substationDetails,
      ...editedDetails,
    };
    await axios.put(url, updatedSubstationDetails, getToken()).catch(e => console.error(e));
    localStorage.setItem("substation", JSON.stringify(updatedSubstationDetails));
    editfunc(false);
  };




  const OpenDialogEdit = () => {
    setIsEditDialogOpen(true);


  }
  return (
    <div className="edit-user-container">
   

<button
  style={{ cursor: jsonSchema.properties.cursor }}
  onClick={handleClick}
  className="interactive-paragraph"
>

        <span>
          <Icon
            sizeClass={jsonSchema.properties.icon.sizeClass}
            name={jsonSchema.properties.icon.name}
          />
        </span>
        {"  "}
        <span>{jsonSchema.properties.text.default}</span>

      </button>

      {jsonSchema.enabled && (
        <p className="edit-user-header">
          {jsonSchema.title} {substationDetails.name}
        </p>
      )}

      {jsonSchema.properties.title.enabled && (
        <p className="edit-user-sub-header">
          {jsonSchema.properties.title.title}
        </p>
      )}
      <table id="edit-user-table">
        <tbody>
          <tr>
            {jsonSchema.properties.name.enabled && (
              <td>

                <Input
                  dataType="text"
                  type="normal"
                  label={jsonSchema.properties.name.title}
                  sizeClass={uiSchema.name["ui:options"].sizeClass}
                  style={uiSchema.name["ui:options"].style}
                  value={editedDetails?.name || substationDetails?.name || ''}
                  onValueChange={(value) => handleInputChange('name', value)}
                />
              </td>
            )}
            <td>

              {jsonSchema.properties.substationId.enabled && (
                <Input
                  type="normal"
                  style={uiSchema.substationId["ui:options"].style}
                  label={jsonSchema.properties.substationId.title}
                  sizeClass={uiSchema.substationId["ui:options"].sizeClass}
                  value={substationDetails?.substationId}
                  disabled={uiSchema.substationId["ui:options"].disabled}
                />
              )}
            </td>
          </tr>
          <tr>
            {jsonSchema.properties.type.enabled && (
              <td className="edit-user-col">


                <Input
                  label={jsonSchema.properties.type.title}
                  sizeClass={uiSchema.type["ui:options"].sizeClass}
                  value={editedDetails?.type || substationDetails?.type || ''}
                  onValueChange={(value) => handleInputChange('type', value)}
                />

              </td>
            )}
            <td className="edit-user-col">


              {jsonSchema.properties.coordinates.enabled && (
                <Input
                  style={uiSchema.coordinates["ui:options"].style}
                  label={jsonSchema.properties.coordinates.title}
                  sizeClass={uiSchema.coordinates["ui:options"].sizeClass}
                  value={editedDetails?.coordinates || substationDetails?.coordinates || ''}
                  onValueChange={(value) => handleInputChange('coordinates', value)}
                />
              )}
            </td>
          </tr>
          <tr>
            <td className="edit-user-col">

              {/* Save Button */}
              {jsonSchema.properties.saveButton.enabled && (
                <Button
                  text={jsonSchema.properties.saveButton.properties.text.default}
                  shape={jsonSchema.properties.saveButton.properties.shape.default}
                  sizeClass={jsonSchema.properties.saveButton.properties.sizeClass.default}
                  type={jsonSchema.properties.saveButton.properties.type.default}
                  onClick={() => OpenDialogEdit()}
                />
              )}
              {/* // Cancel Button */}
              {jsonSchema.properties.cancelButton.enabled && (
                <Button
                  text={jsonSchema.properties.cancelButton.properties.text.default}
                  shape={jsonSchema.properties.cancelButton.properties.shape.default}
                  sizeClass={jsonSchema.properties.cancelButton.properties.sizeClass.default}
                  type={jsonSchema.properties.cancelButton.properties.type.default}
                  onClick={() => editfunc(false)}
                />
              )}
            </td>
          </tr>
        </tbody>
      </table>
      {isEditDialogOpen &&
        (
          <div className="overlay-dr">
            <div className="dialog-containe-edit-user">

              {jsonSchema.properties.dialog.enable && (
                <Dialog
                  className={jsonSchema.properties.dialog.properties.className.default}
                  showCloseButton={jsonSchema.properties.dialog.properties.showCloseButton.default}
                  closeOnEscape={jsonSchema.properties.dialog.properties.closeOnEscape.default}
                  dimBackground={jsonSchema.properties.dialog.properties.dimBackground.default}
                  hideBackground={jsonSchema.properties.dialog.properties.hideBackground.default}
                  title={jsonSchema.properties.dialog.properties.title.default}
                  standardButtonsOnBottom={[
                    {
                      text: jsonSchema.properties.dialog.properties.standardButtonsOnBottom.default[0].text,
                      type: jsonSchema.properties.dialog.properties.standardButtonsOnBottom.default[0].type,
                      handler: () => handleEdit() // Handler for 'Yes' button
                    },
                    {
                      text: jsonSchema.properties.dialog.properties.standardButtonsOnBottom.default[1].text,
                      type: jsonSchema.properties.dialog.properties.standardButtonsOnBottom.default[1].type,
                      handler: () => setIsEditDialogOpen(false) // Handler for 'No' button
                    }
                  ]}
                />
              )}
            </div>
          </div>
        )
      }
    </div>
  );
};


 
 
EditSubstation.propTypes = {
  editfunc: PropTypes.node.isRequired,
};

export default EditSubstation;
