import React, { useEffect, useState, useContext } from "react";
import { LoginScreen, Link, Icon } from "@abb/abb-common-ux-react";
import { useNavigate } from "react-router-dom";
import { RootContext } from "../../contexts/root-context";
import { Auth, Amplify } from "aws-amplify";
import { awsConfig } from "../../components/Authentication/aws-config";
import { BASE_URL, MESSAGES } from "../../strings";
import axios from "axios";
import { getToken } from "../../components/Authentication/get-token";
import "./login.css";
import { encryptData } from "../../util";
import { Input, Button, Dialog } from "@abb/abb-common-ux-react";
import qrcode from 'qrcode'

Amplify.configure(awsConfig);

function ABBLogin() {
  const { isnotify } = useContext(RootContext);
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [count, setCount] = React.useState(0);
  const [showVerifyMFA, setShowVerifyMFA] = useState(false);
  const [totpCode, settotpCode] = React.useState();
  const [formErrors, setFormErrors] = useState({});
  const [getLoginConfig, setgetLoginConfig] = useState({});
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [ShowQRCode, setShowQRCode] = useState(false);
  const [QRCodeURL, setQRCodeURL] = useState('');
  const [user, setUser] = useState(null);
  const [showPostCannotGiveCodeStep, setShowPostCannotGiveCodeStep] = useState(false);
  const [showSecretKey, setShowSecretKey] = useState(false);
  const [ShowMFASettingUpMessage, setShowMFASettingUpMessage] = useState(false);
  const [showSessionTimeout, setShowSessionTimeout] = useState(false);
  useEffect(() => { }, [showPostCannotGiveCodeStep]);

  useEffect(() => {
    localStorage.setItem("error", "");
    redirectToLogin();
  }, []);

  useEffect(() => {
  }, [isError]);

  useEffect(() => {
  }, [showVerifyMFA])

  useEffect(() => {
    if (ShowQRCode && QRCodeURL) {
    // Generate QR code and display it on the screen
    // Timing issue where the QR code is not generated in time
    let canvas = document.getElementById('canvas');
    qrcode.toCanvas(canvas, QRCodeURL, function (error) {
      if (error) {
        console.error('Error generating QR code:', error);
      }
      console.log('QR code generated successfully');
    });
  }
  }, [ShowQRCode, QRCodeURL]);

  useEffect(() => {
    let timer;
    if(ShowQRCode) {
      // Show modal after 3 minutes i.e. 180,000 milliseconds
      timer = setTimeout(() => {
        setShowSessionTimeout(true);
      }, 180000);
    }
    return () => clearTimeout(timer); // Cleanup the timer when we exit the screen
  }, [ShowQRCode]);
    
  const handleForgotPasswordClick = () => {
    navigate("../forgot-password");
  };

  const redirectToLogin = () => {
    try {
      if (window.location.pathname !== "/") {
        navigate("../");
      }
    } catch (err) { }
  };

  const showSuccess = () => {
    navigate("./substation-explorer");
  };

  const lawText =
    "This is a private system. Do not attempt to logon unless you are an authorized user. " +
    "Any authorized or unauthorized access and use may be monitored and can result in " +
    "criminal or civil prosecution under applicable law.";

  const [usersstate, setUsersState] = useState({
    username: "",
    password: "",
    error: false,
  });

  const gotSignUp = () => {
    navigate("../signup");
  };

  const goForgotPassword = () => {
    navigate("../change-password");
  };

  const postLoginFetchUserDetails = async (username) => {
    try {
      const response = await axios.get(
        BASE_URL + `rbac-get?id=` + username.trim(),
        getToken()
      );
      const userInfo = response.data;

      userInfo ? localStorage.setItem("USER_INFO", JSON.stringify(userInfo)) : postLoginFetchUserDetails()

    } catch (error) { } finally { }
  };

  const handleLoginSubmit = async (username, password) => {
    localStorage.clear();
    setIsError(false);
    setLoading(true);
    try {
      const signInOptions = {
        username,
        password,
      };
      setgetLoginConfig(signInOptions);
      const user = await Auth.signIn(signInOptions);
      // Check if MFA is required for the user
      const challengeParam = user.challengeName;
      if (challengeParam === "MFA_SETUP") {
        try {
          setLoading(false);
          const secretCode = await Auth.setupTOTP(user);
          setUser(user);
          // QR code string
          const authCode = `otpauth://totp/${process.env.REACT_APP_AWS_COGNITO_DOMAIN}.auth.eu-central-1.amazoncognito.com:` + user.username + "?secret=" + secretCode;
          setShowQRCode(true);
          setQRCodeURL(authCode);

        } catch (error) {
          console.error("Error during MFA setup:", error);
        }
      } else if (challengeParam === "SOFTWARE_TOKEN_MFA") {
        setLoading(false);
        setShowVerifyMFA(true);
        settotpCode()
        setUser(user);
        // const totpCode = prompt(
        //   "Enter One--time password code from your authenticator app:"
        // );
        //await verifyMFACode(user, username)
      }

      const encryptedUserName = encryptData(username);
      localStorage.setItem("logged_in_user", encryptedUserName);
      localStorage.setItem("error", "");
      setIsError(false);
      setUsersState({ ...usersstate, iserror: false });
    } catch (error) {

      setLoading(false);
      setIsError(true);

      switch (error.code) {
        case "UserNotConfirmedException":
          localStorage.setItem("error", "User account not confirmed. Please check your email for verification instructions.");
          break;
        // Incorrect Username
        case "UserNotFoundException":
        // Incorrect Password
        case "NotAuthorizedException":
          localStorage.setItem("error", "Incorrect username or password.");
          break;
        case "PasswordResetRequiredException":
          localStorage.setItem("error", "Password reset required. Please reset your password.");
          break;
        case "LimitExceededException":
          localStorage.setItem("error", "Attempt limit exceeded. Please try again later.");
          break;
        default:
          localStorage.setItem("error", "An unexpected error occurred. Please try again later.");
          break;




      }
      setUsersState({
        ...usersstate,
        iserror: true,
        error: true,
      });
    };
  }
  const verifyMFACode = async () => {
    setIsError(false);
    setLoading(true);
    if (totpCode) {
      const user = await Auth.signIn(getLoginConfig);
      try {
        let confirmSignInResp = await Auth.confirmSignIn(
          user,
          totpCode,
          "SOFTWARE_TOKEN_MFA"
        );
        console.log("confirmSignInResp", confirmSignInResp)
        // Check if MFA challenge is successfully completed before proceeding
        if (confirmSignInResp.username) {
          await postLoginFetchUserDetails(getLoginConfig.username);

          setShowVerifyMFA(false)
          setIsError(false);
          showSuccess();
        }
      } catch (e) {
        if (e.name === 'CodeMismatchException') {
          const message = e.message;
          const truncatedMessage = message.split(':')[0];
          localStorage.setItem("error", truncatedMessage);
        } else {
          console.log('An unexpected error occurred:', e.message);
          localStorage.setItem("error", e.message);

        }
        setIsError(true);

      }
    } else {
      setIsError(true);
      localStorage.setItem("error", "Enter Valid Code");
    }
  }

  const verifyMFASetup = async () => {
    setIsError(false);
    setLoading(true);
    if (totpCode) {
      try {
        // Verify the TOTP token
        await Auth.verifyTotpToken(user, totpCode);
        // Reaching here means MFA setup is successful
        
        // setLoading(false);
        setShowMFASettingUpMessage(true);
        await postLoginFetchUserDetails(getLoginConfig.username);
        setShowVerifyMFA(false)
        setIsError(false);
        showSuccess();

      } catch (error) {
        setIsError(true);
        localStorage.setItem("error", error.message);
        console.log("Error verifying MFA code:", error);
        setLoading(false);
      }
    } else {
      setIsError(true);
      localStorage.setItem("error", "Enter Valid Code");
    }
  };

  const resetMFA = async () => {
    setShowPostCannotGiveCodeStep(true);

    const AWS = require('aws-sdk');
    AWS.config.update({
      region: 'eu-central-1',
      credentials: awsConfig.awsConfigUserpool.credentials
    });

    try {
      // Get the secret code from DynamoDB
      const dynamodb = new AWS.DynamoDB.DocumentClient();
      const params = {
        TableName: 'SECRET_CODE_TABLE',
        Key: {
          email: getLoginConfig.username // Partition key
        }
      };
      const secret_data = await dynamodb.get(params).promise();
      console.log('Secret code retrieved successfully:', secret_data.Item);
      
      const qrCodeText = MESSAGES.MFA_QR_CODE_PREFIX + user.username + MESSAGES.MFA_QR_CODE_SECRET_PREFIX + secret_data.Item.secretCode;

      // Check if the QR code is in S3 storage
      const s3_bucket_name = 'qr-code-storage-test';
      const s3 = new AWS.S3({ params: { Bucket: s3_bucket_name } });

      const getObjectParams = {
        Bucket: s3_bucket_name,
        Key: user.username + '.png',
      };

      let s3ImageURL = '';
      try {
        await s3.headObject(getObjectParams).promise();
        s3ImageURL = await s3.getSignedUrlPromise('getObject', {
          Bucket: s3_bucket_name,
          Key: user.username + '.png',
          Expires: 60 * 60 * 24 // URL will expire in 24 hours
        });
        console.log('QR Code URL:', s3ImageURL);  
      } catch (error) {
        if (error.code === 'NotFound') {
          console.error('QR code does not exist in S3');
        } else {
          console.error('Error checking QR code in S3:', error);
        }
      }

      // If the QR code does not exist in S3, generate a new one
      if(s3ImageURL === '') {

        // Generate QR code and save it to S3 storage
        let qrcode = require('qrcode')
        let qrCodeData = ''
        qrcode.toDataURL(qrCodeText, function (err, data_url) {
          if(err) {
            console.error('Error generating QR code:', err);
          }
          qrCodeData = data_url;
        });
        // console.log('QR code data:', qrCodeUrl);

        // Convert data URL to buffer
        const { Buffer } = require('buffer');
        const base64 = qrCodeData.replace(/^data:image\/\w+;base64,/, '');
        const base64Data = Buffer.from(base64, 'base64');
        const type = qrCodeData.split(';')[0].split('/')[1];

        // Upload the QR code image to S3
        const s3_bucket_name = 'qr-code-storage-test';
        const s3 = new AWS.S3({ params: { Bucket: s3_bucket_name } });
        const s3Params = {
          Bucket: s3_bucket_name,
          Key: user.username + '.' + type,
          Body: base64Data,
          ContentEncoding: 'base64',
          ContentType: 'image/' + type
        }
        
        try {
          const data = await s3.upload(s3Params).promise();
          console.log('File uploaded successfully:', data);

          // Get the pre-signed URL for the uploaded object
          const getObjectParams = {
            Bucket: s3_bucket_name,
            Key: data.Key,
            Expires: 60 * 60 * 24 // URL will expire in 24 hours
          };

          const objectUrl = await s3.getSignedUrlPromise('getObject', getObjectParams);
          console.log('Object URL:', objectUrl);
          s3ImageURL = objectUrl;

        } catch (err) {
          console.error('Error uploading file:', err);
        }

        // For permanent url uncomment these lines
        // const s3ImageURL = `https://${s3_bucket_name}.s3.eu-central-1.amazonaws.com/sample.png`;
        // console.log('S3 Image URL:', s3ImageURL);
      }

      // Send the QR code to the user's email
      const ses = new AWS.SES();
      
      const EmailBody = () => {
        return (
          <html>
            <head>
              <title> {MESSAGES.MFA_EMAIL_SUBJECT} </title>
            </head>
            <body>
              <div id="qr-code">
                <h3> Hi User, </h3>
                <h3> {MESSAGES.MFA_EMAIL_SUBJECT} </h3> 
                <b> {MESSAGES.MFA_EMAIL_TITLE} </b>
                <p> {MESSAGES.MFA_INSTRUCTIONS_STEP_1} </p>
                <p> {MESSAGES.MFA_INSTRUCTIONS_STEP_2} </p>
                <img src={s3ImageURL} title="QR Code" alt="QR Code" width="200" height="200" style={{
                  maxWidth: '240px'
                }}/>
                <p> {MESSAGES.MFA_INSTRUCTIONS_ALTERNATIVE_STEP_FOR_EMAIL} <br /> {secret_data.Item.secretCode} </p>
              </div>
              <div>
              Thanks, <br />
              BEDAS CogniEN Cloud <br /> <br />

              <img src={MESSAGES.MFA_EMAIL_ABB_LOGO_URL} alt="ABB logo" style={{
                  height: '60px',
                  position: 'relative',
                  width: '240px',
                  maxWidth: '240px'
                }} />
              </div>
            </body>
          </html>
        );
      }
      
      const ReactDOMServer = require('react-dom/server');
      const emailBodyHTML = ReactDOMServer.renderToString(<EmailBody />);

      const params_ses = {
        Source: MESSAGES.MFA_EMAIL_SENDER,
        Destination: {
          ToAddresses: [getLoginConfig.username]
        },
        Message: {
          Subject: {
            Data: MESSAGES.MFA_EMAIL_SUBJECT
          },
          Body: {
            Html: {
              Data: emailBodyHTML
            },
            Text: {
              Data: MESSAGES.MFA_EMAIL_SUBJECT + '\n' +
              MESSAGES.MFA_EMAIL_TITLE + '\n' +
              MESSAGES.MFA_INSTRUCTIONS_STEP_1 + '\n' +
              MESSAGES.MFA_INSTRUCTIONS_STEP_2 + '\n' +
              MESSAGES.MFA_INSTRUCTIONS_ALTERNATIVE_STEP_FOR_EMAIL + qrCodeText + '\n' +
              "Thanks," + "\n" +
              "BEDAS CogniEN Cloud"
            }
          }
        }
      };

      // Send the email
      try {
        const emailResponse = await ses.sendEmail(params_ses).promise();
        console.log('Email sent successfully:', emailResponse);
      } catch (error) {
        console.log("Error sending email:", error);
      }

    } catch (error) {
      console.error('Error:', error);
    }
  };

  const closeDialog = () => {
    setShowPostCannotGiveCodeStep(false);
    setShowSessionTimeout(false);
    setShowQRCode(false);
    setShowVerifyMFA(false);
  }

  const cancelMFASetup = () => {
    setShowQRCode(false);
  }

  const handleShowSecretKey = () => {
    setShowSecretKey(prevState => !prevState);
  };

  const cancelForgotPwd = () => {
    setShowVerifyMFA(false)
    setIsError(false);
    setLoading(false);
    navigate("./");
  }
  const handleIdpLogin = async () => {
    try {
      Auth.federatedSignIn({ provider: "SAML" });
      console.log('SAML Login successful')
    } catch (err) {
      console.error('Error during SAML login:', err);
    } finally {
    }
  };

  return (
      <>
      {(showSessionTimeout || showPostCannotGiveCodeStep) && (
        <>
          <div class="ReactModal__Overlay ReactModal__Overlay--after-open ABB_CommonUX_Dialog__overlay ABB_CommonUX_Dialog__halfOpacityBackdrop ABB_CommonUX_Dialog__light">
            <Dialog
              className="signup-dialog-container"
              title={showSessionTimeout ? MESSAGES.MFA_SESSION_EXPIRED_TITLE : MESSAGES.MFA_POPUP_TITLE}
            >
              <p>{showSessionTimeout ? MESSAGES.MFA_SESSION_EXPIRED : MESSAGES.MFA_POPUP_CONTENT}</p>

              <Button
                sizeClass={"medium"}
                text="Back to Login"
                type="primary-blue"
                onClick={closeDialog}
              />
            </Dialog>
          </div>
        </>
      )}
      { ShowQRCode ? (
      <div className="ABB_CommonUX_LoginScreen__root ABB_CommonUX_LoginScreen__light login-container">
        <div className="ABB_CommonUX_LoginScreen__header">
          <div />
          <div className="ABB_CommonUX_LoginScreen__logo" />
        </div>
        <div className="ABB_CommonUX_LoginScreen__contentWrapper">
          <div className="ABB_CommonUX_LoginScreen__content">
            <h1 className="ABB_CommonUX_LoginScreen__productName">Set up authenticator app</h1>
            <div>
              <br />
              <div className="ABB_CommonUX_LoginScreen__customContent" style={{ fontSize: "14px", fontWeight: 500, textAlign: "left" }}>
                <p> {MESSAGES.MFA_INSTRUCTIONS_STEP_1}
                <br /> {MESSAGES.MFA_INSTRUCTIONS_STEP_2 + MESSAGES.MFA_INSTRUCTIONS_ALTERNATIVE_STEP}
                <Link onClick={handleShowSecretKey} discreet={true}>{showSecretKey ? 'Hide Secret Key' : 'Show Secret Key'} </Link> </p>
                <div id="secret-key">
                  {showSecretKey && <p> Your secret key is: {QRCodeURL.split('secret=')[1]} </p>}
                </div>
              </div>
            </div>
            {QRCodeURL && (
              <div>
                <canvas id="canvas" width="200" height="200" style={{
                  margin: "0px",
                  padding: "0px"
                }}></canvas>
              </div>
            )}
            <div className="ABB_CommonUX_LoginScreen__form">
              <div className="ABB_CommonUX_LoginScreen__formRow">
                <div className="ABB_CommonUX_LoginScreen__customContent" style={{ fontSize: "14px", fontWeight: 600 }}>
                  Enter a code from your authenticator app
                  <div />
                </div>
              </div>
              <div className="ABB_CommonUX_LoginScreen__formRow" style={{ width: "400px", fontSize: "14px", fontWeight: 500, marginTop: "0px" }}>
                <div className="ABB_CommonUX_LoginScreen__password">
                  <div className="ABB_CommonUX_Input__root ABB_CommonUX_Input__light ABB_CommonUX_Input__normal ABB_CommonUX_Input__isValid ABB_CommonUX_Input__isEmpty">
                    <Input
                      className="ABB_CommonUX_Input__inputElement"
                      type="normal"
                      name="toptp"
                      id="toptp"
                      value={totpCode}
                      onValueChange={(val) => {
                        setIsSaveEnabled(true)
                        settotpCode(val);
                      }} />
                    {ShowMFASettingUpMessage && (
                      <p style={{ color: "green" }}>{MESSAGES.MFA_SETUP_TRIGGER}</p>
                    )}
                    {isError && (
                      <p style={{ color: "red" }} id="error-msg">
                        <Icon
                          name="abb/warning-circle-1"
                          className="incorrect"
                        ></Icon>
                        {localStorage.getItem("error")}
                      </p>
                    )}
                    <br />
                    <br />
                    <Button
                      text="Login"
                      id="mfa-login"
                      disabled={!isSaveEnabled}
                      shape={"rounded"}
                      sizeClass={"small"}
                      type={"primary-black"}
                      onClick={() => verifyMFASetup(user)}
                    />
                    &nbsp;&nbsp;<Button
                      text="Cancel"
                      id="mfa-cancel"
                      shape={"rounded"}
                      sizeClass={"small"}
                      type={"primary-black"}
                      onClick={cancelMFASetup}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      ) : (
      showVerifyMFA ? (
        <div className="ABB_CommonUX_LoginScreen__root ABB_CommonUX_LoginScreen__light login-container">
          <div className="ABB_CommonUX_LoginScreen__header">
            <div />
            <div className="ABB_CommonUX_LoginScreen__logo" />
          </div>
          <div className="ABB_CommonUX_LoginScreen__contentWrapper">
            <div className="ABB_CommonUX_LoginScreen__content">
              <h1 className="ABB_CommonUX_LoginScreen__productName">Verify your identity</h1>
              <div className="ABB_CommonUX_LoginScreen__form">
                <div className="ABB_CommonUX_LoginScreen__formRow">
                  <div className="ABB_CommonUX_LoginScreen__customContent" style={{ fontSize: "14px", fontWeight: 600 }}>
                    Enter a code from your authenticator app.
                    <div />
                  </div>
                </div>
                <div className="ABB_CommonUX_LoginScreen__formRow" style={{ width: "400px", fontSize: "14px", fontWeight: 500, marginTop: "0px" }}>
                  <div className="ABB_CommonUX_LoginScreen__password">
                    <div className="ABB_CommonUX_Input__root ABB_CommonUX_Input__light ABB_CommonUX_Input__normal ABB_CommonUX_Input__isValid ABB_CommonUX_Input__isEmpty">
                      <Input
                        className="ABB_CommonUX_Input__inputElement"
                        type="normal"
                        name="toptp"
                        id="toptp"
                        value={totpCode}
                        onValueChange={(val) => {
                          setIsSaveEnabled(true)
                          settotpCode(val);
                        }} />
                      {loading && (
                        <p style={{ color: "green" }}>{MESSAGES.SIGN_IN_TRIGGER}</p>
                      )}
                      {isError && (
                        <p style={{ color: "red" }} id="error-msg">
                          <Icon
                            name="abb/warning-circle-1"
                            className="incorrect"
                          ></Icon>
                          {localStorage.getItem("error")}
                        </p>
                      )}
                      <br />
                      <br />
                      <Button
                        text="Login"
                        id="mfa-login"
                        disabled={!isSaveEnabled}
                        shape={"rounded"}
                        sizeClass={"small"}
                        type={"primary-black"}
                        onClick={verifyMFACode}
                      />
                      &nbsp;&nbsp;<Button
                        text="Cancel"
                        id="mfa-cancel"
                        shape={"rounded"}
                        sizeClass={"small"}
                        type={"primary-black"}
                        onClick={cancelForgotPwd}
                      />
                     <div>
                        <br />
                        <br />
                        <br />
                        Don't have code? <Link onClick={resetMFA} discreet={true}>Reset MFA</Link>
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) :
        (<LoginScreen
          productName="Log in"
          className="login-container"
          footerText={lawText}
          onLogin={(username, password) => {
            setCount(count + 1);
            if (username === "" || password === "") {
              setIsError(true);
              localStorage.setItem("error", "Enter Valid Username and password");
            } else if (username && password) {
              handleLoginSubmit(username, password);
            } else {
              setIsError(true);
              localStorage.setItem("error", "Enter Valid Username and password");
            }
          }}
          customContent={(props, state) => {
            setUsersState(state);
            return (
              <>
                <div id="ad-signup-controls">
                  {/* <UserSession /> */}
                  {/* Dont have an account <Link onClick={gotSignUP} >SignUp</Link> */}
                  {/* <p> Sign in to your CogniEN account </p> */}

                  <div className="ad-container">

                    <span> Don't have an account <Link onClick={gotSignUp}>SignUp</Link></span>

                    <span style={{ "marginLeft": "150px" }}> <Link onClick={handleIdpLogin}>Login with ABB Corporate ID</Link></span>

                    <br /> <br />
                  </div>

                </div>
                <div className="success">
                  {loading && (
                    <p style={{ color: "green" }}>{MESSAGES.SIGN_IN_TRIGGER}</p>
                  )}
                  <div className="success2">
                    {isError && (
                      <p style={{ color: "red" }} id="error-msg">
                        <Icon
                          name="abb/warning-circle-1"
                          className="incorrect"
                        ></Icon>
                        {localStorage.getItem("error")}
                      </p>
                    )}
                  </div>
                </div>
              </>
            );
          }}
          customButtons={
            [
              {
                text: "Forgot Password?",
                handler: () => handleForgotPasswordClick(),
              },
            ]
           }
        />)
      )}
    </>
  );

}

export default ABBLogin;
